<template>
  <div>
    <div class="flight-content">
      <div class="section dt-detail-info">
        <div class="row dt-detail-info__header">
          <div class="col-md-12 heading">Member Information</div>
        </div>

        <customer :item="item"></customer>
        <pending :item="item"></pending>
        <sendby :item="item"></sendby>
      </div>
      <actions :item="item"></actions>
    </div>
  </div>
</template>

<script>
import Pending from "./Pending.vue";
import Customer from "./Customer.vue";
import Sendby from "./Sendby.vue";
import Actions from "./Actions.vue";

export default {
  name: "InvitationDetails",
  components: {
    Pending,
    Sendby,
    Customer,
    Actions,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
