<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <div class="row address">
        <div class="col-md-3">
          <span class="label">Sent By :</span>
          <!-- <span class="val">{{ item.sent_by }}</span> -->
        </div>
      </div>
      <div class="row address">
        <div class="col-md-3">
          <span class="val">{{ item.sent_by }}</span>
        </div>
        <div class="col-md-3 val">nr : {{ item.member_display_id }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
