<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            Invitations
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-card-body>
          <b-row>
            <b-col md="12">
              <data-table
                :table-columns="tableColumns"
                :table-data="invitationList"
                :border="true"
                row-class-name="row-expanded"
                :loading="loading"
              >
                <template #header-action>
                  <base-button
                    v-permission="[GRANT.ADD_INVITE]"
                    size="md"
                    type="primary"
                    @click="toggleAddInvitaitionForm(true)"
                  >
                    <span class="fas fa-plus"></span>
                    Add Invitation
                  </base-button>
                </template>
                <template #expandable="{ item }">
                  <invitation-details :item="item"></invitation-details>
                </template>
              </data-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
    <add-invitaition
      :show-add-invitaition-modal-form="showAddInvitaitionModalForm"
      @close="toggleAddInvitaitionForm(false)"
    ></add-invitaition>
  </div>
</template>

<script>
import { RouteBreadCrumb } from "@/components";
import { invitationService } from "@/core/services";
import InvitationDetails from "./components/Details.vue";
import { mapGetters } from "vuex";
import AddInvitaition from "./components/AddInvitation.vue";
import { GRANT } from "@/core/enums";

export default {
  name: "Invitation",
  components: {
    RouteBreadCrumb,
    InvitationDetails,
    AddInvitaition,
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          sortable: true,
          searchable: true,
        },
        {
          prop: "phone",
          label: "Telephone No",
          searchable: true,
        },
        {
          prop: "invited_by",
          label: "by",
          searchable: true,
        },
        {
          prop: "date",
          label: "date",
        },
      ],
      tableData: [],
      loading: false,
      showAddInvitaitionModalForm: false,
      GRANT: GRANT,
    };
  },
  computed: {
    dtRef() {
      return this.$refs.dt;
    },
    ...mapGetters("invitation", ["invitationList"]),
  },
  mounted() {
    this.getAllinvitationList();
  },
  methods: {
    toggleAddInvitaitionForm(val) {
      this.showAddInvitaitionModalForm = val;
    },
    getAllinvitationList() {
      this.loading = true;
      invitationService.invitations().finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dt-detail-info {
    &__content-sec {
      &--passenger {
        .heading {
          margin-bottom: 0.8em;
          margin-top: 1em;
          font-size: 0.8rem;
          font-weight: 700;
        }
        .pass-detail {
          margin-bottom: 0.5em;
        }
      }
      &--pricing {
        .price-detail {
          margin-bottom: 0.8em;
          .col-md-3 {
            display: flex;
          }
          .badge {
            margin-right: 1em;
          }
          .time-label {
            font-weight: 500;
            width: 40%;
          }
          .time-value {
            font-weight: 500;
          }
        }
      }
      &--customer {
        .address {
          padding-bottom: 0.18em;
          .label {
            font-weight: 600;
            color: #807d7d;
            padding-right: 1em;
          }
        }
      }
    }
  }
}
</style>
