<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--customer"
    >
      <div class="row address">
        <div class="col-md-3">
          <span class="val">Pending {{ calculate_date(item.date) }} days</span>
          <span class="val"></span>
          <!-- <span class="val">{{ item.telephone_no }}</span> -->
        </div>
      </div>
      <div class="row address">
        <div class="col-md-6">
          <span class="val">Date : {{ item.date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
      date: {
        type: Number,
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    moment: () => moment,
  },
  methods: {
    calculate_date: function (date) {
      console.log();
      var given = moment(date, "YYYY-MM-DD");
      var current = moment().startOf("day");
      return Math.abs(moment.duration(given.diff(current)).asDays());
    },
  },
};
</script>

<style lang="scss" scoped></style>
