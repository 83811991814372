<template>
  <div>
    <div class="dt-detail-footer">
      <div class="action-list">
        <div class="action-list__item">
          <a
            v-permission="[GRANT.DELETE_INVITE]"
            href="javascript:void(0)"
            @click="delete_invite()"
          >
            Delete Invitation
          </a>
        </div>
        <div class="action-list__item">
          <a
            v-permission="[GRANT.RESEND_INVITE]"
            href="javascript:void(0)"
            @click="resend_invite()"
          >
            Resend Invitation
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { invitationService, confirmDialogService } from "@/core/services";
import { GRANT } from "@/core/enums";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    tableRef: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      GRANT: GRANT,
    };
  },
  computed: {},
  methods: {
    delete_invite() {
      confirmDialogService.open(
        "Are you sure you want to delete this invitation?",
        () => {
          invitationService.remove(this.item.id).then((_res) => {
            invitationService.drop(this.item.id);
          });
        }
      );
    },
    resend_invite() {
      confirmDialogService.open(
        "Are you sure you want to resend invitation?",
        () => {
          invitationService.resend_invite(this.item.id).then((_res) => {});
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
